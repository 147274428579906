import { useEffect, useState } from "react";
import {useLocation } from "react-router-dom";
import logo from '../assets/img/logo.png'
import humanDoor from '../assets/img/property_1_room_entrance.png'
import qr from '../assets/img/qr.png'
import './Room.css';
import moment from 'moment'
import 'moment/locale/tr'  // without this line it didn't work
moment.locale('tr')


const Room = () => {
	
	let [oda1, setOda1] = useState(null); 
	let [oda2, setOda2] = useState(null); 
	let [date, setDate] = useState(""); 

	let { search } = useLocation();
 
	const get = (oda1get,oda2get) => {
		if(oda1get) {
			console.log("Yenileme...");
			setDate(moment().format('DD MMMM YYYY, HH:mm'))
			fetch(`https://ismekan-api.arneca.com/room/${oda1get}`)
			.then(response => response.json())
			.then(data => {
	   
				setOda1(data.result)
			});
		}
		if(oda2get) {    
			fetch(`https://ismekan-api.arneca.com/room/${oda2get}`)
			.then(response => response.json())
			.then(data => {
		
				setOda2(data.result)
			});
		}
	}

	useEffect(()=>{
		console.log(search);
		search = search.replace("&amp;amp;","&")
		const query = new URLSearchParams(search);
		const odalar = query.get('odalar');
		console.log("odalar",odalar);
		let arr = odalar.split("-");
		const oda1get = arr[0];
		const oda2get = arr[1];
		document.querySelector("body").classList.add("full")
		console.log("oda1get",oda1get);
		console.log("oda2get",oda2get);
		get(oda1get,oda2get);
	  
		const timer = setInterval(() => {
			get(oda1get,oda2get);
		}, 15000);

	},[]);
	
	return  <div>
	
					<>
						<section className="topBar">
						<img src={logo} alt="logo" />
						<div className="dateTime">
							{moment().format('DD MMMM YYYY, HH:mm')}
						</div>
						</section>
						<section className="container">
			   
							 <div className="room left">
							 <QR_Container oda={oda1} />
							 <Calendar oda={oda1} />
						 </div>
				 
		 
						<div className="room right">
							<Calendar oda={oda2} />
							<QR_Container oda={oda2} reversed />
						</div>
	  
						</section>
					</>
				
			   
			</div>
}

export default Room;

const QR_Container = ({oda, reversed}) => {
	if(oda) {
		return (
			<div className={"nonCalBar" + (reversed?" reverse":"")}>
				<h3 className="title">{oda.name}</h3>
				<div className="qrContainer">
					<p>Kapıyı açmak için İşmekan mobil uygulamanızla  bu QR’ı okutun,</p>
					<img style={{width: '100%'}} crossorigin="anonymous" src={`https://ismekan-api.arneca.com/generate/${oda._id}`} alt="QR Code" />
				</div>
				<img className={"bottomPic" + (reversed?" reverse":"")} src={humanDoor} alt="human picture" />
			</div>
		)
	}
  
}

const Calendar = ({ oda }) => {
	if(oda) {
		return (
			<div className="calBar">
				<div className="calendar">
					{
						(oda.times && oda.times.length > -1) && (oda.times.map( (it) => {
							let classNames = it.active ? "" : "disabled";
							classNames += it.expired ? " expired" : "";
							return (
								<div className={classNames}>
									<p>{it.start}</p>
									<p>{it.end}</p>
								</div>
							)
						}))
					}
				</div>
				<span className="bottomLabel">
					Müsait saat aralıkları
				</span>
				<span className="bottomLabel-expired" style={{ marginTop: "-300px" }}>
					Geçmiş saat aralıkları
				</span>
				<span className="bottomLabel-selected" style={{ marginTop: "-300px" }}>
					Seçilmiş saat aralıkları
				</span>

			</div>
		)
	}
}
