
import { useState, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Room from "./Pages/Room";


export default function App() {


  const innerPageStart = {
    position: "fixed",
    width: "100vw",
    height: "calc(100vh - 60px)",
    top: "60px",
    backgroundColor: "#f4f4f4",
    display: "flex",
    justifyContent: "center"
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div style={innerPageStart} className="pages">
          <Routes>
            <Route path="/" element={ <Room />} />
            <Route path="/*" element={<NoPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>

  );
}


function NoPage() {
  return <>Nothing To Show</>
}